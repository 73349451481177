$(function(){
    $('.one').on('mouseenter mouseleave', function(){
        $('.hovercardone').toggleClass('active');
    });
});


$(function(){
    $('.two').on('mouseenter mouseleave', function(){
        $('.hovercardtwo').toggleClass('active');
    });
});


$(function(){
    $('.three').on('mouseenter mouseleave', function(){
        $('.hovercardthree').toggleClass('active');
    });
});