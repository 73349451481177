jQuery(document).ready(function($){
	AOS.init();
	document.addEventListener( 'wpcf7mailsent', function( event ) {
	    location = 'https://ilkleychiropractic.co.uk/thank-you/';
	    ga('send', 'event', 'Contact Form', 'submit');
	}, false );
});

jQuery(document).ready(function(){
	jQuery('#review_btn').click(function(){
		jQuery('#testimonial_form').addClass('show_modal');
		jQuery('#overlay').addClass('show_overlay');

		var url = window.location.href;
		jQuery('.url').val(url);
		jQuery('body').addClass('no_scroll');
		overflow_3();
	}); 

	jQuery('#testimonial_form .close, #overlay').click(function(){
		jQuery('#testimonial_form').removeClass('show_modal');
		jQuery('#overlay').removeClass('show_overlay');
		jQuery('body').removeClass('no_scroll');
	});

	overflow_3();

	$(window).on('resize', function(){
		overflow_3();
	});

	function overflow_3(){
		if( $('#testimonial_form').hasClass('show_modal') ){
			var elem = $('#testimonial_form');
			var height = elem.innerHeight();
			var window_height = $(window).height();

			if( height == window_height ){
				elem.addClass('scroll');
			}else{
				elem.removeClass('scroll');
			}
		}
	}
});

// menu dropdown
jQuery(document).ready(function($){
	$('#menu-item-26').hover(
		function(){
			show_menu( $(this) );
			
		}, function(){
			hide_menu( $(this) );
		}
	);

	function show_menu(elem){
		var menu = elem.find('.sub-menu');
		menu.addClass('show');
	}

	function hide_menu(elem){
		var menu = elem.find('.sub-menu');
		menu.removeClass('show');
	}
});

// scroll elements on click
$("a[href^='#']").click(function(e) {
	e.preventDefault();
	var position = $($(this).attr("href")).offset().top;

	$("body, html").animate({
		scrollTop: position
	} /* speed */ );
});

//services on header dropdown
jQuery(document).ready(function($){ 
    $('.opening').on('click', function(e) {
      $('.openingtimes').toggleClass("active");  
      e.preventDefault();
    });
});

// 
jQuery(document).ready(function($){ 
	document.addEventListener( 'wpcf7mailsent', function(event){
		ga( 'send', 'event', 'Contact Form', 'submit' );
	}, false );
});