jQuery(document).on('click', '#more:not(.loading)', function(){
	var $ = jQuery; 

	var btn = $(this);
	// get current page
	var cur_page = btn.data('page');
	// get next page num to update button data attr
	var next_page = btn.data('page')+1;
	// add loading class so fuction cannot be run while already running
	btn.addClass('loading');

	$.ajax({
		url: ajax_url,
		type: 'post',
		dataType: 'json',
		data: { 
			page: cur_page,
			action: 'load_more',
		},
		error : function(response){
			console.log(response);
		},
		success : function(response){
			console.log(response);
			btn.data('page', next_page);

			for(var i = 0; i < response.post_cont.length; i++){
				var html =''+
					'<div class="col-12 col-lg-9 testimonial_post">'+
						'<div class="row">'+
							'<div class="col-md-12">'+
								'<p class="name">'+ response.post_cont[i].name +'</p>'+
								'<p class="testimonial">'+ response.post_cont[i].testimonial +'</p>'+
								'<p class="editorial">'+ response.post_cont[i].edit +'</p>'+
							'</div>'+
						'</div>'+
						'<hr>'+
					'</div>';

				jQuery('#testimonial_container').append(html);
			}

			btn.removeClass('loading');

			if( jQuery('.testimonial_post').length >= response.max_posts[0].no_of_posts){
				btn.hide();
			}
		}
	});
}); 