jQuery(document).on('click', '#load_more:not(.loading)', function(){
	var $ = jQuery; 
 
	var btn = $(this);
	// get current page
	var cur_page = btn.data('page');
	// get next page num to update button data attr
	var next_page = btn.data('page')+1;
	// add loading class so fuction cannot be run while already running
	btn.addClass('loading');

	$.ajax({
		url: ajax_url,
		type: 'post',
		dataType: 'json',
		data: { 
			page: cur_page,
			action: 'blog_load_more',
		},
		error : function(response){
			console.log(response);
		},
		success : function(response){
			console.log(response);
			// udate to new page num
			btn.data('page', next_page);

			for(var i = 0; i < response.blog_data.length; i++){
				var html = ''+
					'<div class="col-sm-4 post">'+
						'<a href="'+ response.blog_data[i].permalink +'">'+
							'<div class="img-fluid"><img src="'+ response.blog_data[i].img +'" class="img-fluid"></div>'+
							'<div class="wrap">'+
								'<p class="text-muted">'+ response.blog_data[i].date +'</p>'+
								'<p class="title">'+ response.blog_data[i].title +'</p>'+
								response.blog_data[i].excerpt+
							'</div>'+
						'</a>'+
					'</div>';

				$('#blogs').append(html);
			}

			btn.removeClass('loading');

			// remove button if max num of post has been reached
			if( jQuery('#blogs .post').length >= response.max_posts[0].no_of_posts){
				btn.hide();
			}
		}
	});
}); 



































































