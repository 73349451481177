$('.slick').slick({
  infinite: true,
  speed: 700,
  autoplaySpeed: 4000,
  arrows: false,
  autoplay: true,
});
jQuery(document).ready(function($){
	$("#hidecovid").click(function(){
		$(".covid").addClass("active");

	}) 
});