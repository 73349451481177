jQuery(document).ready(function($){

	// slider1 button
	$('.slider1btn').tilt({
	    scale: 1.05,
	    glare: true,
	    maxGlare: .5
	})
	// slider3button
	$('.slider3btn').tilt({
	    scale: 1.05,
	    glare: true,
	    maxGlare: .4
	})


	$('.col-md-4 .one').tilt({
	    scale: 1.05,
	    glare: true,
	    maxGlare: .4
	})

});

