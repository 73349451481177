$(function(){
    $('.conditions').on('mouseenter mouseleave', function(){
        $(this).find('.over').toggleClass('active');
    });
});

$(function(){
    $('.sports').on('mouseenter mouseleave', function(){
        $(this).find('.over').toggleClass('active');
    });
});